body {
  background-color: #f2f6fa;
  margin: 0;
  padding: 0;
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

* {
  font-family: Gilroy;
}

html {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

button,
input[type='submit'],
input[type='reset'] {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
}

input {
  color: black;
  background-color: white;
}

textarea:focus,
input:focus {
  outline: none;
}

.ant-picker-input > input::placeholder {
  color: #7f7f7f;
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
}

.ant-picker-input > input {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  border-radius: 24px;
  width: 100%;
  padding: 4px;
}

.ant-picker-focused {
  box-shadow: none;
}

.ant-table {
  background: transparent !important;
}

.ant-table-thead .ant-table-cell {
  background-color: transparent !important;
  color: #7b7b7b;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 1.2px;
  text-transform: uppercase;
}

.ant-table-cell {
  color: #000;
  font-family: Gilroy;
  font-style: normal;
  font-weight: 300;
  border: 0.2px solid #d5e4f6 !important;
}

/*th.ant-table-cell.ant-table-row-expand-icon-cell {*/
/*  background-color: red !important;*/
/*}*/

.ant-table-thead {
  font-size: 10px;

  > * {
    height: auto !important;
  }

  > :first-child {
    background: #d5e4f6 !important;

    > * {
      border: 0.2px solid #d5e4f6 !important;
    }
  }

  > :not(:first-child) {
    background: #eaf2fb !important;

    > * {
      border: 0.2px solid #d5e4f6 !important;
    }
  }
}

.ant-collapse-header {
  padding: 0 !important;
}

.ant-table-cell-row-hover {
  background-color: transparent !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.table-highlighted {
  background-color: #f2f6fa;
}

* {
  scrollbar-color: #f2f6fa transparent;

  scrollbar-width: thin;

  &::-webkit-scrollbar {
    width: 8px;
    background-color: transparent;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #f2f6fa;
  }
}
